@charset 'UTF-8';
/*
  SCSS mixins that implement things in the Bedsider' new 2021 style guide:
    https://zpl.io/bWZwedj
*/
@use "sass:color";
@import "./colors";
@import "./fonts";
@import "./twentyone_responsive_whitespace";
@import "./z_index";

/**
  ----------------------------------------
  ::section:: Breakpoints
*/

/**
  ::name:: tablet_and_up
  ::description:: screen width 525px and up
  ::html::
    <p> This text becomes bold at tablet width </p>
  ::scss::
p {
  color: $medium_gray;
  @include tablet_and_up {
    font-weight: bold;
    color: $twentyone_black;
  }
}
*/
@mixin tablet_and_up {
  @media (min-width: 525px) {
    @content;
  }
}

/**
  ::name:: desktop_and_up
  ::description:: screen width 1024px and up
  ::html::
    <p> This text becomes bold at desktop width </p>
  ::scss::
p {
  color: $medium_gray;
  @include desktop_and_up {
    font-weight: bold;
    color: $twentyone_black;
  }
}
*/
@mixin desktop_and_up {
  @media (min-width: 1024px) {
    @content;
  }
}

/**
  ::name:: wide_screen
  ::description:: screen width 1280px and up
  ::html::
    <p> This text becomes bold on wide screens </p>
  ::scss::
p {
  color: $medium_gray;
  @include wide_screen {
    font-weight: bold;
    color: $twentyone_black;
  }
}
*/
@mixin wide_screen {
  @media (min-width: 1280px) {
    @content;
  }
}

/**
  ::name:: mobile_and_below
  ::description::
      Screen width 525px and below.
      Strong preference to NOT use these two; when possible,
      set the mobile and override using the x_and_up rules.

  ::html::
    <p> This text becomes bold on mobile and smaller </p>
  ::scss::
p {
  color: $medium_gray;
  @include mobile_and_below {
    font-weight: bold;
    color: $twentyone_black;
  }
}
*/
@mixin mobile_and_below {
  @media (max-width: 525px) {
    @content;
  }
}

/**
  ::name:: tablet_and_below
  ::description::
      Screen width 525px and below. Use other mixins first.

  ::html::
    <p> This text becomes bold on tablets and smaller </p>
  ::scss::
p {
  color: $medium_gray;
  @include tablet_and_below {
    font-weight: bold;
    color: $twentyone_black;
  }
}
*/
@mixin tablet_and_below {
  @media (max-width: 1024px) {
    @content;
  }
}

// ----------- SPACING ----------- //

/**
  ::name:: twentyone_standard_outside_padding

  ::html::
<div class="outer">
  <div class="inner">
     Nisi dolore anim nulla eiusmod ullamco mollit ut voluptate proident pariatur nostrud in.
  </div>
</div>

  ::scss::
.outer {
  @include twentyone_standard_outside_padding;
  background-color: $twentyone_hover_field_green;
}
.inner {
  background-color: white;
}
*/
@mixin twentyone_standard_outside_padding {
  padding-left: 6.25vw;
  padding-right: 6.25vw;

  @include tablet_and_up {
    padding-left: 5.7vw;
    padding-right: 5.7vw;
  }

  @include desktop_and_up {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  @include wide_screen {
    padding-left: 64px;
    padding-right: 64px;
  }

  // This is the point where the main content well gets to be 1280px (64px + 1280px + 64px).
  // This calculation will keep it there.
  @media (min-width: 1408px) {
    padding-left: calc((100vw - 1280px) * 0.5);
    padding-right: calc((100vw - 1280px) * 0.5);
  }
}

@mixin twentyone_standard_outside_margin {
  margin-left: 6.25vw;
  margin-right: 6.25vw;

  @include tablet_and_up {
    margin-left: 5.7vw;
    margin-right: 5.7vw;
  }

  @include desktop_and_up {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  @include wide_screen {
    margin-left: 64px;
    margin-right: 64px;
  }

  // This is the point where the main content well gets to be 1280px (64px + 1280px + 64px).
  // This calculation will keep it there.
  @media (min-width: 1408px) {
    margin-left: calc((100vw - 1280px) * 0.5);
    margin-right: calc((100vw - 1280px) * 0.5);
  }
}

// ----------- SUB-MIXINS ----------- //

/**
  ----------------------------------------
  ::section:: Buttons
*/

// this mixin is applied to button mixins to make them white-button mixins
@mixin twentyone_button_turn_white {
  background-color: white;
  border: 2px solid $twentyone_dark_green;
  color: $twentyone_dark_green;

  &:hover {
    background-color: white;
    border-color: $twentyone_hover_dark_green;
    color: $twentyone_hover_dark_green;
  }

  &:active {
    background-color: white;
    border-color: $twentyone_pressed_dark_green;
    color: $twentyone_pressed_dark_green;
  }

  &:disabled {
    background-color: white;
    border-color: $twentyone_disabled_dark_green;
    color: $twentyone_disabled_dark_green;

    &:hover {
      background-color: white;
      border-color: $twentyone_disabled_dark_green;
      color: $twentyone_disabled_dark_green;
    }
  }
}

/**
  ::name:: twentyone_button
  ::html:: <button> Button </button>
  ::scss:: button { @include twentyone_button; }
*/
@mixin twentyone_button {
  @include twentyone_buttonfont1;
  background-color: $twentyone_dark_green;
  color: white;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 5px;
  padding: 15px 20px 16px;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $twentyone_hover_dark_green;
    box-shadow:
      0 1px 4px 1px rgba(60, 64, 67, 0.15),
      1px 1px 3px 0 rgba(60, 64, 67, 0.3);
    text-decoration: none;
  }

  &:active {
    background-color: $twentyone_pressed_dark_green;
  }

  &:disabled {
    background-color: $twentyone_disabled_dark_green;
    box-shadow: none;
    cursor: auto;

    &:hover {
      background-color: $twentyone_disabled_dark_green;
    }
  }
}

/**
  ::name:: twentyone_button_white
  ::html:: <button> Button </button>
  ::scss:: button { @include twentyone_button_white; }
*/
@mixin twentyone_button_white {
  @include twentyone_button;
  @include twentyone_button_turn_white;
  padding: 13.5px 20px; // two px shorter than normal button
  background-color: white;
}

/**
  ::name:: twentyone_button_short
  ::html:: <button> Button </button>
  ::scss:: button { @include twentyone_button_short; }
*/
@mixin twentyone_button_short {
  @include twentyone_button;
  padding: 10.5px 20px;
}

/**
  ::name:: twentyone_button_short_white
  ::html:: <button> Button </button>
  ::scss:: button { @include twentyone_button_short_white; }
*/
@mixin twentyone_button_short_white {
  @include twentyone_button;
  @include twentyone_button_turn_white;
  padding: 8.5px 20px; // two px shorter than normal button
}

/**
  ::name:: twentyone_button_secondary
  ::html:: <button> Button </button>
  ::scss:: button { @include twentyone_button_secondary; }
*/
@mixin twentyone_button_secondary {
  @include twentyone_button_short;
  font-size: 0.875rem;
}

/**
  ::name:: twentyone_button_secondary_white
  ::html:: <button> Button </button>
  ::scss:: button { @include twentyone_button_secondary_white; }
*/
@mixin twentyone_button_secondary_white {
  @include twentyone_button_short_white;
  @include twentyone_button_turn_white;
  padding: 8.5px 20px; // two px shorter than normal button
  font-size: 0.875rem;
}

/**
  ::name:: twentyone_button_min_width
  ::description:: Apply this to another button for a standard min width.
  ::html:: <button> Button </button>
  ::scss::
button {
  @include twentyone_button;
  @include twentyone_button_min_width;
}
*/
@mixin twentyone_button_min_width {
  @include desktop_and_up {
    min-width: 273px;
  }
}

/**
  ::name:: twentyone_button_fab
  ::description:: BYO up-arrow image
  ::html:: <button></button>
  ::scss:: button { @include twentyone_button_fab; }
*/
@mixin twentyone_button_fab {
  // "floating action button"
  @include twentyone_button;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  padding: 12px 0;

  // fighting with the default min width
  @include desktop_and_up {
    min-width: unset;
  }

  &.fab-es {
    width: 64px;
    height: 64px;
  }

  img {
    margin: 0 12px;
  }

  .text {
    @include lucida_sans;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: white;
    margin-top: 4px;
  }
}

/**
  ::name:: twentyone_button_nav_arrow
  ::html:: <button class='left'></button> <button class='right'></button>
  ::scss:: button { @include twentyone_button_nav_arrow; }
  ::description:: Use '.left' and '.right' classes for their respective arrows.
*/
@mixin twentyone_button_nav_arrow {
  @include twentyone_button;
  color: white;
  padding: 0;
  border-radius: 2px;

  // fighting with the default min width
  @include desktop_and_up {
    min-width: unset;
  }

  &.left::after {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="8px" height="12px" viewBox="0 0 11 18"> <g fill="none" fill-rule="evenodd"> <g fill="white" fill-rule="nonzero"> <path d="M69.5 320.929l6.427-7.04c.474-.519 1.243-.519 1.717 0 .475.52.475 1.362 0 1.881l-7.285 7.98c-.475.52-1.243.52-1.718 0l-7.285-7.98c-.475-.519-.475-1.361 0-1.88.474-.52 1.243-.52 1.717 0l6.427 7.039z" transform="translate(-64 -310) rotate(90 69.5 318.82)"/> </g> </g> </svg>');
  }

  &.right::after {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="8px" height=" 12px" viewBox="0 0 11 18"><g fill="none" fill-rule="evenodd"> <g fill="white" fill-rule="nonzero"> <path d="M108.5 320.929l6.427-7.04c.474-.519 1.243-.519 1.717 0 .475.52.475 1.362 0 1.881l-7.285 7.98c-.475.52-1.243.52-1.718 0l-7.285-7.98c-.475-.519-.475-1.361 0-1.88.474-.52 1.243-.52 1.717 0l6.427 7.039z" transform="translate(-103 -310) rotate(-90 108.5 318.82)"/> </g> </g> </svg>');
  }
}

/**
  ----------------------------------------
  ::section:: Input Elements
*/

/**
  ::name:: twentyone_radio
  ::html::
<input type='radio' name="magic_user" value='wizard' checked='1'>
<input type='radio' name="magic_user" value='witch'>
  ::scss:: input { @include twentyone_radio; }
*/
@mixin twentyone_radio {
  // visibility: hidden; // this makes them untabbable
  width: 24px;
  height: 24px;
  cursor: pointer;

  &::after {
    display: block;
    content: "";
    visibility: visible;
    width: 22px;
    height: 22px;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><g fill="none" fill-rule="evenodd"><g fill="%23FFF" stroke="gray" transform="translate(-651 -631)"><circle cx="662" cy="642" r="10"/></g></g></svg>');
  }

  &:checked {
    &::after {
      background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 22 22" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;"><g transform="matrix(1,0,0,1,1,1)"><g><g><circle cx="10" cy="10" r="10" style="fill:white;stroke:rgb(128,128,128);stroke-width:1px;"/><circle cx="10" cy="10" r="8" style="fill:rgb(2,131,136);"/></g></g></g></svg>');
    }
  }
}

/**
  ::name:: twentyone_checkbox
  ::html::
<input type='checkbox' value='warlock' checked='1'>
<input type='checkbox' value='magician'>
  ::scss:: input { @include twentyone_checkbox; }
*/
@mixin twentyone_checkbox {
  // visibility: hidden; // this makes them untabbable
  width: 20px;
  height: 20px;
  cursor: pointer;

  &::after {
    display: block;
    content: "";
    visibility: visible;
    width: 20px;
    height: 20px;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21"><g fill="none" fill-rule="evenodd"><g fill="%23FFF" stroke="gray"><g transform="translate(-946 -631) translate(946 631.5)"><rect width="19" height="19" x=".5" y=".5" rx="2"/></g></g></g></svg>');
  }

  &:checked {
    &::after {
      background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><g><g transform="translate(-988 -632) translate(988 632)"><rect width="19" height="19" x=".5" y=".5" fill="%23FFF" stroke="gray" rx="2"/><rect width="14" height="14" x="3" y="3" fill="%23028388" rx="1"/></g></g></g></svg>');
    }
  }

  &[disabled] {
    opacity: 0.38;
    cursor: auto;
  }

  &:focus {
    outline: 1px solid $twentyone_dark_green;
  }
}

/**
  ::name:: twentyone_textfield
  ::description:: Works for many types of inputs. Add an 'error' class to the label for error styling.
  ::html::
<label>
  <div class='title'>Text input</div>
  <input type='text' value='Lorem ipsum'>
  <div class='hint'>Some kind of hint</div>
</label>

<label class='error'>
  <div class='title'>Error state</div>
  <input type='text' value='Lorem ipsum'>
  <div class='hint'>Instructional error message</div>
</label>

<label>
  <div class='title'>Longer text input</div>
  <textarea>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</textarea>
</label>

<label>
  <div class='title'>It works for select too</div>
  <select name='days'>
    <option value='' disabled='true'>select a lorem:</option>
    <option value='4'>4 lorem</option>
    <option value='5'>5 lorem</option>
  </select>
</label>

  ::scss:: label { @include twentyone_textfield; }
*/
@mixin twentyone_textfield {
  display: block;
  margin: 20px 0;

  > .title {
    @include archer_bold;
    font-size: 1rem;
    color: $twentyone_dark_green;
    margin: 5px 0;
  }

  input[type="text"],
  textarea,
  select {
    @include archer_medium;
    font-size: 1rem;
    color: $twentyone_black;
    background-color: white;
    border: solid 1px $twentyone_dark_gray;
    border-radius: 2px;

    padding: 17px 14px 17px;
    box-sizing: border-box;
    width: 100%;

    &:placeholder-shown {
      border-color: $twentyone_gray;
    }

    &:focus {
      outline: none;
      border-color: $twentyone_dark_green;
      border-width: 2px;
      padding: 16px 13px 16px; // slightly reduce padding to keep text stationary even with increased border width
    }

    &::placeholder {
      font-style: italic;
      color: $twentyone_gray;
    }

    &:invalid:required {
      border-color: $twentyone_red;
    }

    option {
      @include lucida_sans;
      font-weight: normal;
    }
  }

  select {
    @include twentyone_select_arrow;
  }

  .hint {
    @include archer_medium;
    font-size: 0.875rem;
    color: $twentyone_black;
    margin: 9px 0;
  }

  &.error {
    .title {
      color: $twentyone_red;
    }

    input[type="text"],
    textarea {
      border-color: $twentyone_red;
    }

    .hint {
      @include archer_semibold;
      color: $twentyone_red;

      &::before {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 6px;
        // padding-top: 2px;
        vertical-align: -3px;
        content: "";
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 17 18" version="1.1" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g><g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,17,17.5)"><circle cx="8.5" cy="5" r="1" style="fill:rgb(216,66,0);"/></g><g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,17,17.5)"><path d="M9.5,8.006L9.5,11.994C9.5,12.549 9.052,13 8.5,13C7.948,13 7.5,12.55 7.5,11.994L7.5,8.006C7.5,7.451 7.948,7 8.5,7C9.052,7 9.5,7.45 9.5,8.006Z" style="fill:rgb(216,66,0);fill-rule:nonzero;"/></g><g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,17,17.5)"><path d="M8.5,0C3.806,0 0,3.806 0,8.5C0,13.194 3.806,17 8.5,17C13.194,17 17,13.194 17,8.5C17,3.806 13.194,0 8.5,0ZM13.442,13.442C11.682,15.232 9.096,15.941 6.668,15.298C4.241,14.655 2.345,12.759 1.702,10.332C1.059,7.904 1.768,5.319 3.558,3.558C5.318,1.768 7.904,1.059 10.332,1.702C12.759,2.345 14.655,4.241 15.298,6.668C15.941,9.096 15.232,11.681 13.442,13.442Z" style="fill:rgb(216,66,0);fill-rule:nonzero;"/></g></g></svg>');
      }
    }
  }
}

/**
  ::name:: twentyone_select
  ::html::
<label>
  <div class='title'>number of days</div>
  <select name='days'>
    <option value='' disabled='true'>select a lorem:</option>
    <option value='4'>4 lorem</option>
    <option value='5'>5 lorem</option>
  </select>
</label>
  ::scss:: label { @include twentyone_select; }
*/
@mixin twentyone_select {
  display: block;
  border: solid 1px #dedede;
  border-radius: 4px;
  margin: 12px 0;
  padding: 10px 8px 2px;
  box-sizing: border-box;
  width: max-content;

  .title {
    @include archer_semibold;
    font-size: 0.75rem;
    color: $twentyone_dark_green;
    background-color: white;
    padding-left: 4px;
  }

  select {
    @include twentyone_select_arrow;
    @include archer_book;
    color: $twentyone_black;
    font-size: 0.875rem;
    border: none;
    padding: 6px 20px 6px 0;
    cursor: pointer;

    option {
      font-family: sans-serif;
      font-size: 1rem;
    }
  }
}

/**
  ::name:: twentyone_admin_select
  ::html::
<select name='days'>
  <option value='' disabled='true'>select a lorem:</option>
  <option value='4'>4 lorem</option>
  <option value='5'>5 lorem</option>
</select>
  ::scss:: select { @include twentyone_admin_select; }
*/
@mixin twentyone_admin_select {
  @include lucida_sans;
  @include twentyone_select_arrow;
  color: $twentyone_black;
  font-size: 0.875rem;

  display: block;
  border: solid 1px #cdcdcd;
  background-color: #f9f9f9;

  padding: 10px 15px;
  box-sizing: border-box;
  width: max-content;

  option {
    font-family: sans-serif;
    font-size: 1rem;
  }
}

// for select dropdowns, gives a custom arrow
@mixin twentyone_select_arrow {
  cursor: pointer;

  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px 6px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6"><g fill="none" fill-rule="evenodd"><g fill="%23028388" fill-rule="nonzero"><g><path d="M112.5 35.19l3.78-3.97c.28-.293.732-.293 1.01 0 .28.293.28.767 0 1.06l-4.285 4.5c-.279.293-.731.293-1.01 0l-4.286-4.5c-.279-.293-.279-.767 0-1.06.28-.293.731-.293 1.01 0l2.345 2.461 1.436 1.508z" transform="translate(-757 -818) translate(649.5 787)"/></g></g></g></svg>');

  /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  } // for IE 10 & 11
}

/**
  ----------------------------------------
  ::section:: Other
*/

/**
  ::name:: twentyone_card
  ::html::
<div> Junk in this card's trunk. Milkshake <a href='#'>meet yard.</a> </div>
<div class='disabled'> This card has been disabled. </div>
  ::scss:: div { @include twentyone_card; }
*/
@mixin twentyone_card {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);
  border-radius: 6px;
  margin: 20px 0;
  padding: 12px;
  background-color: white;

  &.disabled {
    box-shadow: none;
    border: solid 1px #f5f5f5;
  }

  a {
    @include twentyone_link;
    font-weight: bold;
    // text-decoration: underline;
  }
}

/**
  ::name:: twentyone_flexcard
  ::html::
<a>
  <div class='title'>Card Title</div>
  This card can be displayed in a row of 3 on desktop or stacked on mobile, in Bedsider’s primary and secondary colors.
  <div class='call-to-action'>Call to action >></div>
</a>
  ::scss:: a { @include twentyone_flexcard; }
*/
@mixin twentyone_flexcard {
  @include twentyone_card;
  @include archer_semibold;

  display: flex;
  min-height: 150px;
  max-width: 370px;
  padding: 16px 20px;
  font-weight: 600;
  text-decoration: none;
  flex-direction: column;
  line-height: 1.5;
  background-color: $twentyone_dark_green;
  color: white;
  cursor: pointer;

  &:hover {
    box-shadow:
      0 1px 4px 1px rgba(60, 64, 67, 0.15),
      1px 1px 3px 0 rgba(60, 64, 67, 0.3);

    .call-to-action {
      text-decoration: underline;
    }
  }

  .title {
    @include archer_bold;
    padding-bottom: 6px;
    font-size: 1.5rem;
  }

  .call-to-action {
    @include archer_bold;
    margin-left: auto;
    margin-top: auto;
    white-space: nowrap;
    padding-top: 10px;
    color: white;
    text-decoration: none;
  }
}

/**
  ::name:: twentyone_flash
  ::html::
<div class="FlashFromCookie">
  <div class="flash">Flash message</div>
  <div class="flash warning">Flash warning</div>
  <div class="flash error">Flash error</div>

  <div class="flash error">
    <ul>
      <li>Too many straws</li>
      <li>Not enough camels</li>
    </ul>
  </div>
</div>
  ::scss:: @include twentyone_flash;
*/
@mixin twentyone_flash {
  .flash {
    // position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 12px;
    background-color: #f9c850;
    font-size: 1.25rem;

    ul {
      @include twentyone_ul;
      margin: 4px 0;

      li {
        @include twentyone_body1;
      }

      li::before {
        background-image: none; // override 'twentyone_ul'
      }
    }

    &.warning {
      background-color: $dark_orange;
      color: white;
    }

    &.error {
      background-color: $twentyone_red;
      color: white;

      ul li {
        // overriding 'twentyone_ul'
        color: white;
        font-size: 1.25rem;
        margin-top: 8px;
      }
    }
  }
}

/**
  ----------------------------------------
  ::section:: Text Elements
*/

/**
  ::name:: twentyone_ul
  ::html::
<ul>
  <li>It doesn’t matter what you’re working with in terms of body parts or who you’re working with as a sexual partner</li>
  <li>If your genitals or mouth are touching someone else’s genitals or vice versa or if you’re sharing toys, there’s a risk of STI transmission. </li>
</ul>
  ::scss:: ul { @include twentyone_ul; }
*/
@mixin twentyone_ul {
  list-style: none;
  margin: 14px 0 14px 20px;

  li {
    padding-left: 20px;

    &:not(:first-child) {
      @include responsive_top_margin(20px, 22px, 22px, 22px);
    }

    &::before {
      content: "";
      display: block;
      float: left;
      height: 1em;
      width: 1em;
      margin-left: -1.5em;
      margin-top: 0.5em;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 12 12' ><circle r='1.5' fill='%23028388'/></svg>");
      background-size: 100%;
    }
  }
}

/**
  ::name:: twentyone_ol
  ::html::
<ol>
  <li>Here’s the thing: whether or not birth control is needed for you to prevent pregnancy is not about your sexuality or gender identity.</li>
  <li>Another awesome benefit of birth control is that it can reduce gender dysphoria for folks who are trans or nonbinary–both by regulating or stopping periods and by preventing pregnancy, both of which can trigger gender dysphoria for some people.</li>
</ol>
  ::scss:: ol { @include twentyone_ol; }
*/
@mixin twentyone_ol {
  counter-reset: li;
  list-style: none;
  margin-left: 36px;
  @include tablet_and_up {
    margin-left: 48px;
  }

  li::before {
    @include twentyone_body1;
    content: counter(li) ".";
    position: absolute;
    color: $twentyone_dark_green;
    font-weight: 600;

    left: -28px;
    @include tablet_and_up {
      left: -34px;
    }
  }

  li {
    counter-increment: li;
    position: relative;
    padding-top: 12px;
    @include tablet_and_up {
      padding-top: 20px;
    }
  }
}

/**
  ::name:: twentyone_link
  ::html:: Trans <a href='#'>rights</a>
  ::scss:: a { @include twentyone_link; }
*/
@mixin twentyone_link {
  color: $twentyone_dark_green;
  display: inline;
  text-decoration: none; // have to opt-into it

  &:active,
  &:hover {
    color: $twentyone_hover_dark_green;
    cursor: pointer;
  }

  em {
    color: $twentyone_dark_green;
  }
}

/**
  ::name:: twentyone_link_external
  ::html:: Trans <a href='#'>lefts</a>
  ::scss:: a { @include twentyone_link_external; }
*/
@mixin twentyone_link_external {
  @include twentyone_link;
  font-weight: bold;

  @include desktop_and_up {
    &:hover {
      text-decoration: underline;
    }
  }

  &::after {
    display: inline-block;
    width: 12px;
    height: 12px;
    padding-top: 2px;
    margin-left: 8px;
    content: "";
    vertical-align: -3px;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19 17"><g fill="none" fill-rule="evenodd"><g transform="translate(-280 -310)"><g fill="%23028388" stroke="%23028388" stroke-width=".5"><path d="M14.285 7.792v6.493H1.299V1.299h6.493V0H1.299C.585 0 0 .584 0 1.299v12.986c0 .714.585 1.298 1.299 1.298h12.986c.714 0 1.298-.584 1.298-1.298V7.792h-1.298z" transform="translate(281 310.5)"/><path d="M17 5.667V.708c0-.39-.317-.708-.708-.708h-4.959v1.416h3.248L8.5 7.498 9.502 8.5l6.081-6.08v3.247H17z" transform="translate(281 310.5)"/></g></g></g></svg>');
  }
}

/**
  ::name:: twentyone_breadcrumbs
  ::html::
<ul>
  <li><a href="#">Category</a></li>
  <li><span>Feature</span></li>
</ul>
  ::scss:: ul { @include twentyone_breadcrumbs; }
*/
@mixin twentyone_breadcrumbs {
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  li {
    display: inline;

    span {
      @include archer_medium;
    }

    a {
      @include archer_semibold;
      @include twentyone_link;
      text-decoration: none;

      &::after {
        @include twentyone_link;
        content: " ›";
      }
    }
  }
}

/**
  ::name:: twentyone_tag
  ::html:: <a href='#'>LGBTQ</a>
  ::scss:: a { @include twentyone_tag; }
*/
@mixin twentyone_tag {
  @include twentyone_body2;
  @include responsive_top_margin(17px, 18px, 19px, 20px);
  text-align: center;
  margin-right: 15px;
  text-decoration: none;
  display: inline-block;
  border: 1px solid $twentyone_dark_green;
  border-radius: 999px;
  color: $twentyone_hover_dark_green;
  padding: 1px 10px;
  line-height: 25px;

  @include tablet_and_up {
    padding-left: 15px;
    padding-right: 15px;
    line-height: 30px;
  }

  &:hover {
    background-color: $twentyone_pressed_dark_green;
    color: white;
  }
}

/**
  ::name:: twentyone_squiggle
  ::html:: <div>Text with squiggle</div>
  ::scss:: div { @include twentyone_squiggle; }
*/
@mixin twentyone_squiggle {
  color: $twentyone_dark_green;
  background-repeat: repeat-x;
  background-position-y: 100%;
  width: max-content;
  background-image: url('data:image/svg+xml;utf8, <svg width="100%" height="100%" viewBox="0 0 97 21" version="1.1" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"> <g transform="matrix(1,0,0,1,-0.0750532,0)"> <g id="layer101"> <path d="M48.599,16.067C58.007,16.113 65.106,12.757 72.672,7.978C77.161,5.143 86.677,0.707 97.172,0.974L97.172,5.475C91.292,5.554 83.764,7.13 75.065,12.442C64.462,18.918 58.48,20.768 48.599,20.782L48.599,20.782L48.575,20.782L48.551,20.782L48.551,20.782C38.67,20.768 32.688,18.918 22.085,12.442C13.386,7.13 5.858,5.554 -0.022,5.475L-0.022,0.974C10.473,0.707 19.989,5.143 24.478,7.978C32.044,12.757 39.143,16.113 48.551,16.067L48.551,16.067L48.575,16.067L48.599,16.067L48.599,16.067Z" style="fill:rgb(40,191,166);fill-rule:nonzero;"/> </g> </g> </svg>');
  // background-size: 32px 12px; // mobile scaled down size - check with designers
  background-size: 40px 15px;
  padding-bottom: 5vw;
  @include tablet_and_up {
    padding-bottom: 16px;
  }
}

/**
  ----------------------------------------
  ::section:: Fonts
*/

/**
  ::name:: twentyone_h0
  ::description:: Bigger than normal h1, for use in hero page titles.
  ::html:: <h1> H0 Title </h1>
  ::scss:: h1 { @include twentyone_h0; }
*/
@mixin twentyone_h0 {
  @include archer_bold;
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_h1
  ::html:: <h1> H1 Title </h1>
  ::scss:: h1 { @include twentyone_h1; }
*/
@mixin twentyone_h1 {
  @include archer_bold;
  font-size: 2rem;
  line-height: 2.625rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 3rem;
    line-height: 3rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_h1_variant
  ::html:: <h1> H1 Variant </h1>
  ::scss:: h1 { @include twentyone_h1_variant; }
*/
@mixin twentyone_h1_variant {
  @include archer_bold;
  @include twentyone_squiggle;
  font-size: 2rem;
  line-height: 2.25rem;
  color: $twentyone_dark_green;

  max-width: 85%; // Make long titles wrap, e.g. /identity_and_inspiration/self_love_and_body_positivity

  @include desktop_and_up {
    max-width: 100%;
    font-size: 2.625rem;
    line-height: 3rem;
  }
}

/**
  ::name:: twentyone_h1_shero
  ::description:: Identical to twentyone_h0, I think; should consolidate.
  ::html:: <h1> H1 Shero </h1>
  ::scss:: h1 { @include twentyone_h1_shero; }
*/
@mixin twentyone_h1_shero {
  @include archer_bold;
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
}

/**
  ::name:: twentyone_h2
  ::html:: <h2> H2 Title </h2>
  ::scss:: h2 { @include twentyone_h2; }
*/
@mixin twentyone_h2 {
  @include archer_bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 2.625rem;
    line-height: 3.25rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_subhead1
  ::html:: <div> Subhead 1 </div>
  ::scss:: div { @include twentyone_subhead1; }
*/
@mixin twentyone_subhead1 {
  @include archer_bold;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_subhead2
  ::html:: <div> Subhead 2 </div>
  ::scss:: div { @include twentyone_subhead2; }
*/
@mixin twentyone_subhead2 {
  @include archer_semibold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: italic;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_subhead2_variant
  ::html:: <div> Subhead 2 variant </div>
  ::scss:: div { @include twentyone_subhead2_variant; }
*/
@mixin twentyone_subhead2_variant {
  @include twentyone_subhead2;
  @include archer_bold;
  font-style: normal;
}

/**
  ::name:: twentyone_subhead3
  ::html:: <div> Subhead 3 </div>
  ::scss:: div { @include twentyone_subhead3; }
*/
@mixin twentyone_subhead3 {
  @include lucida_sans;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_subhead4
  ::html:: <div> Subhead 4 </div>
  ::scss:: div { @include twentyone_subhead4; }
*/
@mixin twentyone_subhead4 {
  @include lucida_sans;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: $twentyone_dark_gray;

  @include tablet_and_up {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_subhead5
  ::html:: <div> Subhead 5 </div>
  ::scss:: div { @include twentyone_subhead5; }
*/
@mixin twentyone_subhead5 {
  @include archer_bold;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  a {
    @include twentyone_link;
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

/**
  ::name:: twentyone_subhead5_variant
  ::html:: <div> Subhead 5 variant </div>
  ::scss:: div { @include twentyone_subhead5_variant; }
*/
@mixin twentyone_subhead5_variant {
  @include twentyone_subhead5;
  @include archer_semibold;
}

/**
  ::name:: twentyone_subhead6
  ::html:: <div> Subhead 6 </div>
  ::scss:: div { @include twentyone_subhead6; }
*/
@mixin twentyone_subhead6 {
  @include archer_semibold;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: $twentyone_black;
  font-style: italic;

  @include tablet_and_up {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  a {
    @include twentyone_link;
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

/**
  ::name:: twentyone_body1
  ::html:: <p> Body 1 </p>
  ::scss:: p { @include twentyone_body1; }
*/
@mixin twentyone_body1 {
  @include lucida_sans;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  a {
    @include twentyone_link;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }
}

/**
  ::name:: twentyone_body2
  ::html:: <p> Body 2 </p>
  ::scss:: p { @include twentyone_body2; }
*/
@mixin twentyone_body2 {
  @include archer_semibold;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  a {
    @include twentyone_link;
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

/**
  ::name:: twentyone_body3
  ::html:: <p> Body 3 </p>
  ::scss:: p { @include twentyone_body3; }
*/
@mixin twentyone_body3 {
  @include lucida_sans;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_caption1
  ::html:: <div> Caption 1 </div>
  ::scss:: div { @include twentyone_caption1; }
*/
@mixin twentyone_caption1 {
  @include lucida_sans;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }

  a {
    @include twentyone_link;
    // disabling the antialiasing wrings a little more boldness
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
  }
}

/**
  ::name:: twentyone_caption2
  ::html:: <div> Caption 2 </div>
  ::scss:: div { @include twentyone_caption2; }
*/
@mixin twentyone_caption2 {
  @include archer_medium;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_buttonfont1
  ::html:: <div> Button font 1 </div>
  ::scss:: div { @include twentyone_buttonfont1; }
*/
@mixin twentyone_buttonfont1 {
  @include archer_bold;
  font-size: 1rem;
  color: $twentyone_black;
}

/**
  ::name:: twentyone_buttonfont1_textlink
  ::html:: <div> Button font 1 (link) </div>
  ::scss:: div { @include twentyone_buttonfont1_textlink; }
*/
@mixin twentyone_buttonfont1_textlink {
  @include twentyone_link;
  @include archer_bold;
  font-size: 1rem;
  text-decoration: underline;
}

/**
  ::name:: twentyone_buttonfont2
  ::html:: <div> Button font 2 </div>
  ::scss:: div { @include twentyone_buttonfont2; }
*/
@mixin twentyone_buttonfont2 {
  @include archer_bold;
  font-size: 0.875rem;
  color: $twentyone_black;

  @include tablet_and_up {
    font-size: 1rem;
  }
}

/**
  ::name:: twentyone_overline1
  ::html:: <div> Overline 1 </div>
  ::scss:: div { @include twentyone_overline1; }
*/
@mixin twentyone_overline1 {
  @include lucida_sans;
  font-weight: 800;
  font-size: 0.8125rem;
  line-height: 1rem;
  color: $twentyone_dark_gray;
  text-transform: uppercase;

  @include tablet_and_up {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_admin_label
  ::html:: <h2> Twentyone Admin Label </h2>
  ::scss:: h2 { @include twentyone_admin_label; }
*/
@mixin twentyone_admin_label {
  @include lucida_sans;
  font-weight: 600;
  font-size: 1rem;
  color: $twentyone_black;

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: twentyone_admin_text
  ::html:: <p> Twentyone Admin Text </p>
  ::scss:: p { @include twentyone_admin_text; }
*/
@mixin twentyone_admin_text {
  @include lucida_sans;
  font-weight: 500;
  font-size: 1rem;
  color: $twentyone_black;

  a {
    @include twentyone_link;
  }
}

/**
  ::name:: archer_light
  ::html:: <p> Archer light </p>
  ::scss:: p { @include archer_light; }
*/

/**
  ::name:: archer_book
  ::html:: <p> Archer book </p>
  ::scss:: p { @include archer_book; }
*/

/**
  ::name:: archer_medium
  ::html:: <p> Archer medium </p>
  ::scss:: p { @include archer_medium; }
*/

/**
  ::name:: archer_semibold
  ::html:: <p> Archer semibold </p>
  ::scss:: p { @include archer_semibold; }
*/

/**
  ::name:: archer_bold
  ::html:: <p> Archer bold </p>
  ::scss:: p { @include archer_bold; }
*/

/**
  ::name:: lucida_sans
  ::description:: Disabling antialiasing can wring out a little more boldness.
  ::html::
<p> Lucida sans </p>
<p class='bold'> Lucida sans (bold) </p>
<p class='extra bold'> Lucida sans (bold w/o antialiasing) </p>
  ::scss::
p {
  @include lucida_sans;

  &.bold {
    font-weight: bold;

    &.extra {
      -moz-osx-font-smoothing: auto;
      -webkit-font-smoothing: auto;
    }
  }
}
*/
