@import "colors";

@font-face {
  // It doesn't like the URL format
  // scss-lint:disable UrlFormat
  font-family: "Glyphicons Halflings";
  src:
    url("//maxcdn.bootstrapcdn.com/bootstrap/3.1.1/fonts/glyphicons-halflings-regular.woff")
      format("woff"),
    url("//maxcdn.bootstrapcdn.com/bootstrap/3.2.0/fonts/glyphicons-halflings-regular.ttf")
      format("truetype");
}

// These mixins match the weight names and
// font rendering settings used in Zeplin.
// (Zeplin's auto-weight-detection is off by 100)
@mixin standardize_antialias_rendering {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin lucida_sans {
  @include standardize_antialias_rendering;

  // Unless they have non-system-default fonts installed,
  // OSX & iOS users will end up seeing Grande, and PC & Mac users will see Unicode.
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Verdana,
    Arial, sans-serif;
}

@mixin archer_light {
  @include standardize_antialias_rendering;
  font-family: "Archer", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Arial, sans-serif;
  font-weight: 400;
  // letter-spacing: -0.05px; // added to make them match design, then removed to make them match
}

@mixin archer_book {
  @include standardize_antialias_rendering;
  font-family: "Archer", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Arial, sans-serif;
  font-weight: 500;
  // letter-spacing: -0.05px;
}

@mixin archer_medium {
  @include standardize_antialias_rendering;
  font-family: "Archer", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Arial, sans-serif;
  font-weight: 600;
  // letter-spacing: -0.05px;
}

@mixin archer_semibold {
  @include standardize_antialias_rendering;
  font-family: "Archer", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Arial, sans-serif;
  font-weight: 700;
  // letter-spacing: -0.05px;
}

@mixin archer_bold {
  @include standardize_antialias_rendering;
  font-family: "Archer", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Arial, sans-serif;
  font-weight: 800;
  // letter-spacing: -0.05px;
}

//
// [Winston Jan 2021] Font mixins below here are are deprecated
//      Nobody makes designs that use these styles.
//

@mixin helvetica {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin monospace {
  font-family: monaco, "Lucida Console", monospace;
}

@mixin serif {
  font-family: georgia, serif;
}

@mixin sans_serif {
  font-family: "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode", "Lucida",
    Verdana, Arial, sans-serif;

  body.windows & {
    font-family: "Lucida Sans Unicode", "Lucida", Verdana, Arial, sans-serif;
  }
}

@mixin helvetica {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin archer_legacy {
  // font-family: 'Archer SSm A', 'Archer SSm B';
  font-family: "Archer", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    Verdana, Arial, sans-serif;
}

@mixin archer_header {
  @include archer_legacy;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

@mixin large_text {
  font-size: 18px;
  line-height: 28px;

  body.windows & {
    font-size: 17px;
  }
}

@mixin medium_text {
  font-size: 16px;
  line-height: 19px;

  body.windows & {
    font-size: 15px;
  }
}

@mixin medium_small_text {
  font-size: 14px;
  line-height: 21px;
}

@mixin small_text {
  font-size: 13px;
  line-height: 19px;

  body.windows & {
    font-size: 12px;
  }
}

@mixin smaller_text {
  font-size: 12px;
  line-height: 21px;

  body.windows & {
    font-size: 11px;
  }
}

@mixin x_small_text {
  font-size: 11px;
  line-height: 21px;

  body.windows & {
    font-size: 10px;
  }
}

@mixin body_copy {
  font-style: normal;
  font-weight: normal;
}

@mixin body_copy_italic {
  font-style: italic;
  font-weight: normal;
  @include serif;
}

@mixin small_italic_header_text {
  @include body_copy_italic;
  @include medium_text;
  color: $dark_gray;
}

@mixin x_small_italic_header_text {
  @include body_copy_italic;
  @include small_text;
  color: $dark_gray;
}

@mixin italic_header_text {
  @include body_copy_italic;
  @include large_text;
  line-height: 20px;
  color: $dark_gray;
}

@mixin italic_header {
  @include italic_header_text;

  border-top: 1px solid $medium_gray;
  border-bottom: 2px solid $dark_gray;

  padding: 17px 0;
  margin-bottom: 15px;

  &::after {
    content: " /";
  }
}

@mixin small_italic_header {
  @include small_italic_header_text;

  margin-bottom: 10px;

  &::after {
    content: " /";
  }
}

@mixin x_small_italic_header {
  @include x_small_italic_header_text;
  margin-bottom: 10px;

  &::after {
    content: " /";
  }
}

@mixin x_small_italic_body_text {
  @include x_small_italic_header_text;
  font-size: 11px;
  line-height: 15px;
  margin: 0;
}

//
// Styles types of text, e.g. titles, body, caption
//

@mixin desktop_article_title {
  @include archer_legacy;
  font-size: 25px;
  line-height: 1.5;
  color: $purple;
  font-style: normal;
  font-weight: 800;
}
