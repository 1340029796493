@import "../../../assets/stylesheets/mixins/fonts";
@import "app/assets/stylesheets/mixins/twentyone_styles";

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.DesktopUserStatus {
  display: flex;
  white-space: pre;

  opacity: 0; // duplicated inline in order to allow it to load in gracefully
  animation: fadein 0.2s;
  animation-delay: 0.4s; // give it a short delay to figure out who the user is
  animation-fill-mode: forwards;

  a#signup,
  a#signin {
    color: $twentyone_black;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $twentyone_dark_green;
      text-decoration: none;
    }
  }
}

#react_login_modal {
  z-index: $z_modal_1998; // underneath modals that might pop up after this
  border-radius: 0;
  text-align: left;
  font-weight: normal;

  .close-text-container {
    display: none;
  }

  .full-screen-modal {
    max-width: 380px;
    width: 100%;
    box-sizing: border-box;

    .modal-content {
      padding: 20px;

      a {
        color: $twentyone_dark_green;
        text-decoration: underline;
        cursor: pointer;
      }

      p {
        @include lucida_sans;
        font-size: 16px;

        &.action {
          font-size: 14px;
        }
        &.instructions {
          font-size: 12px;
          font-weight: bold;
        }
      }

      header {
        line-height: 30px;
        position: relative;

        div.title_header {
          @include archer_semibold;
          font-size: 26px;
          line-height: 30px;
          color: $light_green;
          padding-left: 10px;
          margin: 0;
          width: 300px;
        }

        .toggle_signin {
          @include lucida_sans;
          font-size: 13px;
          line-height: 19px;
          font-style: italic;

          padding-top: 4px;
          padding-left: 10px;

          a {
            padding-left: 5px;
          }
        }

        button.x_close {
          background-image: url('data:image/svg+xml;utf8, <svg width="100%" height="100%" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><g id="Layer1" transform="matrix(1,0,0,1,-292,-167)"><g transform="matrix(1,0,0,1,292.098,167.047)"><path d="M14.549,1.419L1.503,14.488" style="fill:none;stroke:rgb(128,128,128);stroke-width:1.5px;"/></g><g transform="matrix(-0.00089154,1,-1,-0.00089154,308.085,166.981)"><path d="M14.549,1.419L1.503,14.488" style="fill:none;stroke:rgb(128,128,128);stroke-width:1.5px;"/></g></g></svg>');
          width: 16px;
          height: 16px;

          position: absolute;
          right: 0;
          top: 0;

          cursor: pointer;
        }
      }
    }
  }
}
