@import "../../../assets/stylesheets/mixins/defaults";
@import "../../../assets/stylesheets/mixins/fonts";
@import "app/assets/stylesheets/mixins/twentyone_styles";
@import "../../../assets/stylesheets/mixins/forms";
@import "../../../assets/stylesheets/sprites/sprite";

#SignInForm,
#SignUpForm {
  line-height: 1;
  padding: 14px 10px;

  p,
  .feedback,
  .error {
    @include lucida_sans;
    font-weight: normal;
    margin: 6px 0;

    &.action {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .instructions,
  .suggestions,
  .warnings {
    padding-left: 4px;
    font-size: 12px;
    color: $medium_gray;
  }

  form {
    display: flex;
    flex-direction: column;

    .green_button {
      @include green_button;
      @include body_copy_italic;
      align-self: flex-end;
      width: auto;
      padding: 7px 18px;
    }
  }

  fieldset {
    min-height: auto; // Override wizard_common.scss
    height: 46px;
  }

  .FieldSet {
    input {
      color: black;
    }

    select {
      @include lucida_sans;
      font-size: 15px;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      padding: 5px 14px;
      border: none;
      margin: 0;
      background-color: transparent;
      width: 100%;
    }
  }

  .fieldset_with_error {
    border-color: #f37928;

    legend {
      color: #f37928;
    }
  }

  .error {
    @include lucida_sans;
    display: block;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 4px;
    padding-left: 4px;
    width: auto;
    color: #f37928;
  }
}

#SignUpForm {
  // Sign in has a paragraph to do this padding; no such luck for sign up.
  .green_button {
    margin-top: 14px;
  }

  .feedback {
    .warning,
    .suggestions {
      margin: 0;
      color: #808080;
      background: none;
      white-space: initial;
    }
  }
}
