@mixin responsive_side_padding($breakpoints...) {
  padding-left: nth($breakpoints, 1);
  padding-right: nth($breakpoints, 1);

  @include tablet_and_up {
    padding-left: nth($breakpoints, 2);
    padding-right: nth($breakpoints, 2);
  }

  @include desktop_and_up {
    padding-left: nth($breakpoints, 3);
    padding-right: nth($breakpoints, 3);
  }

  @include wide_screen {
    padding-left: nth($breakpoints, 4);
    padding-right: nth($breakpoints, 4);
  }
}

@mixin responsive_top_margin($breakpoints...) {
  margin-top: nth($breakpoints, 1);
  @include tablet_and_up {
    margin-top: nth($breakpoints, 2);
  }
  @include desktop_and_up {
    margin-top: nth($breakpoints, 3);
  }
  @include wide_screen {
    margin-top: nth($breakpoints, 4);
  }
}

@mixin responsive_bottom_margin($breakpoints...) {
  margin-bottom: nth($breakpoints, 1);
  @include tablet_and_up {
    margin-bottom: nth($breakpoints, 2);
  }
  @include desktop_and_up {
    margin-bottom: nth($breakpoints, 3);
  }
  @include wide_screen {
    margin-bottom: nth($breakpoints, 4);
  }
}

@mixin responsive_top_padding($breakpoints...) {
  padding-top: nth($breakpoints, 1);
  @include tablet_and_up {
    padding-top: nth($breakpoints, 2);
  }
  @include desktop_and_up {
    padding-top: nth($breakpoints, 3);
  }
  @include wide_screen {
    padding-top: nth($breakpoints, 4);
  }
}

@mixin responsive_bottom_padding($breakpoints...) {
  padding-bottom: nth($breakpoints, 1);
  @include tablet_and_up {
    padding-bottom: nth($breakpoints, 2);
  }
  @include desktop_and_up {
    padding-bottom: nth($breakpoints, 3);
  }
  @include wide_screen {
    padding-bottom: nth($breakpoints, 4);
  }
}
