@import "../../../../assets/stylesheets/mixins/fonts";

.FieldSet {
  border: 1px solid $light_gray;
  margin-top: 20px;
  padding: 0;

  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &.email {
    width: auto;
  }

  &.zip {
    width: 180px;
  }

  legend {
    @include archer_bold;
    font-size: 15px;

    padding: 0 7px 1px;
    margin-left: 5px;
    color: $dark_medium_gray;
    background-color: white;
  }

  .error {
    color: $dark_orange;
  }

  input {
    @include lucida_sans;
    font-size: 15px;
    line-height: 17px;
    border: 0 solid rgba(0, 0, 0, 0);
    width: 100%;
    box-sizing: border-box;
    padding: 4px 11px 8px 11px;
    background-color: transparent;

    &::placeholder {
      font-style: italic;
      color: $light_gray;
    }
  }

  &.green-theme {
    border-color: $light_green;

    legend {
      color: $light_green;
    }
  }
}
