// Mixins for preferred Bedsider styles.

@import "fonts";
@import "forms";
@import "colors";
@import "responsive";
@import "z_index";

@mixin focused($color) {
  &.selected > a,
  & > a:focus,
  &:hover > a {
    color: $color;
    text-decoration: underline;
  }
}

@mixin dotted_gray_border($side) {
  border-#{$side}: 1px dotted $light_gray;
}

@mixin inline_block {
  display: inline-block;
  // zoom: 1;
}

@mixin border_radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin border_radius_top($radius) {
  -moz-border-radius-topleft: $radius;
  -moz-border-radius-topright: $radius;
  -webkit-border-top-left-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin center($width) {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: $width;
}

@mixin article {
  @include body_copy;
  @include small_text;

  h1,
  h2,
  h3,
  h4 {
    @include body_copy_italic;
  }

  .header {
    margin-bottom: 14px;
  }

  h1 {
    @include large_text;
    color: $purple;
    margin: 20px 0 0 0;
  }

  h2 {
    @include large_text;
    margin: 0 0 5px 0;
  }

  h3 {
    @include large_text;
    @include serif;
    margin-bottom: 20px;
  }

  h4 {
    @include large_text;
    @include serif;
  }

  p {
    @include sans_serif;
    margin-bottom: 10px;
  }

  pre {
    @include sans_serif;
  }

  th,
  td,
  li {
    @include sans_serif;
  }

  li {
    margin: 10px 0;
  }

  th {
    font-weight: bold;
  }

  b,
  strong {
    font-weight: bold;
  }

  blockquote {
    @include serif;
    @include large_text;
    @include body_copy_italic;
  }

  i,
  em,
  em a {
    font-style: italic;
  }

  ul {
    margin: 10px 0;

    li {
      list-style: disc outside;
      margin-bottom: 10px;
      margin-left: 15px;
      display: list-item;
    }
  }

  ol {
    margin: 10px 0;

    li {
      list-style-type: decimal;
      list-style-position: outside;
      margin-bottom: 10px;
      margin-left: 3ex;
    }
  }

  hr {
    border: none;
    height: 2px;
    background-color: $dark_gray;
  }

  // custom CMS tags
  a.stand_alone {
    @include body_copy_italic;
  }

  .footnote {
    @include medium_small_text;
    @include body_copy_italic;
  }

  .intro {
    @include large_text;
    @include serif;

    * {
      @include large_text;
      @include serif;
    }
  }
}

@mixin aside {
  @include base_grid;
  @include grid(4);
  @include omega;

  .articles_list_header {
    @include large_text;
    @include body_copy_italic;
    @include dotted_gray_border(bottom);
    padding-bottom: 7px;
    margin-bottom: 10px;
    color: $dark_gray;
  }

  .article_list li {
    @include dotted_gray_border(bottom);
    @include serif;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

@mixin image_border($size_name, $width, $height) {
  .#{$size_name} {
    line-height: 0;
    width: $width;
    @if $height != "auto" {
      height: $height;
    }
    overflow: hidden;

    img {
      width: $width;
      @if $height != "auto" {
        height: $height;
      }
    }

    &.with_border {
      display: block;
      border: 1px solid $light_gray;
      width: $width - 2px !important;
      @if $height != "auto" {
        height: $height - 2px !important;
      }

      img {
        margin: -1px;
      }
    }
  }
}

@mixin double_bordered_h2 {
  h2 {
    @include italic_header;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

@mixin submit_button($background_color: $light_green) {
  @include medium_text;
  @include serif;
  padding: 0 20px;
  border: none;

  line-height: 29px;
  font-style: italic;
  text-decoration: none;
  color: white;
  background-color: $background_color;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: $lighter_green;
  }
}

@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

// Deprecated -- has styles for fieldsets, but does so much more--yuck
@mixin messy_legacy_fieldset {
  border: 1px solid $light_gray;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  float: none; // override global

  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &.email {
    width: auto;
  }

  &.zip {
    width: 180px;
  }

  legend {
    @include archer_legacy;
    font-size: 14px;
    font-weight: bold;
    padding: 0 8px;
    margin: 0;
    color: $dark_medium_gray;
  }

  .error {
    color: $dark_orange;
  }

  input {
    @include serif;
    border: 0 solid rgba(0, 0, 0, 0);
    width: 100%;
    font-size: 16px;
    padding: 10px 0;
    background-color: transparent;

    &::placeholder {
      font-style: italic;
      color: $light_gray;
    }
  }
}

@mixin green_button {
  @include body_copy;
  -moz-box-align: center;
  -moz-box-sizing: border-box; // Firefox, other Gecko
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-sizing: border-box; // Safari/Chrome, other WebKit
  -webkit-justify-content: center;
  align-items: center;
  background: $twentyone_dark_green;
  border: none;
  box-sizing: border-box; // Opera/IE 8+
  color: white;
  cursor: pointer;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  font-style: italic;
  justify-content: center;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:disabled,
  &:hover:disabled {
    background: $medium_gray;

    cursor: default;
  }

  &:hover {
    background: $twentyone_hover_dark_green;
  }
}

/* Bedsider's default border for <form> inputs: a thin gray line

   Usage:
     input[type=text] {
       @include light_gray_border;
     }
*/
@mixin light_gray_border {
  border: 1px solid $light_gray;
}

/* Bedsider's default styling for checkboxes: <input type="checkbox">

   Usage:
     input[type=checkbox] {
       @include bedsider_checkbox;
     }
*/
@mixin bedsider_checkbox {
  @include light_gray_border;
  border-radius: 3px;
  $checkbox_size: 14px;
  width: $checkbox_size;
  height: $checkbox_size;
  margin: 5px 0 0 0;
  vertical-align: text-bottom;
  margin-right: 5px;

  &:checked {
    background-color: $green_button;
  }
}

/* Bedsider's default styling for radio buttons: <input type="radio">

   Usage:
     input[type=radio] {
       @include bedsider_radio_button;
     }
*/
@mixin bedsider_radio_button {
  @include light_gray_border;
  $checkbox_size: 14px;
  width: $checkbox_size;
  height: $checkbox_size;
  border-radius: $checkbox_size / 2;
  margin: 5px 0 0 0;
  vertical-align: text-bottom;
  margin-right: 5px;

  &:checked {
    background-color: $green_button;
  }
}

/* Bedsider's default styling for <select> pull-downs

   Usage:
      select {
        @include bedsider_select;
      }
*/
@mixin bedsider_select {
  @include bedsider_text_input;
  padding-left: 7px;
  border-radius: 0; // Chrome has a defult radius
  background-image: url(dropdownarrow.png);
  background-size: 12px;
  background-position: right 5px center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

/* Bedsider's default styling for <input type="text"> and <textbox> elements

   Usage:
     input[type=text], textbox {
       @include bedsider_text_input;
     }
*/
@mixin bedsider_text_input {
  @include light_gray_border;
  @include serif;
  font-size: 14px;
  line-height: 1.5;
  padding-left: 10px;
}
