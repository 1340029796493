@import "../../../../assets/stylesheets/mixins/fonts";
@import "../../../../assets/stylesheets/mixins/colors";
@import "app/assets/stylesheets/mixins/twentyone_styles";

@keyframes growin {
  0% {
    max-height: 0;
  }
  99% {
    max-height: 70px;
  }
  100% {
    max-height: unset;
  }
}

// #BackstageNav ID is to win in a css fight with active admin.
nav.BackstageNav#BackstageNav {
  @include lucida_sans;
  font-size: 13px;
  position: relative;
  width: 100%;
  background-color: #5896a4;
  line-height: 1;

  animation: growin 0.1s;
  animation-fill-mode: forwards;

  max-height: 0;

  // @media (max-width: 849px) {
  @media (max-width: 525px) {
    display: none;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    margin: 0; // fight active admin

    @include desktop_and_up {
      @include twentyone_standard_outside_padding;
    }

    &.sub-categories {
      background-color: #6db6c6;
    }

    li {
      list-style: none; // fight active admin

      a {
        display: block;
        padding: 10px 12px;
        color: white;
        text-decoration: none;
        text-shadow: none; // fight active admin

        &.selected-category {
          background-color: #6db6c6;
        }
        &.selected-sublink {
          background-color: #3c7e9f;
        }

        &:hover {
          background-color: #6ea6b4;
        }
      }
    }
  }

  .clock {
    @include lucida_sans;
    position: absolute;
    right: 1px;
    top: 1px;
    background-color: $dark_gray;
    border: 1px solid $light_gray;
    color: $x_light_gray;
    padding: 2px 3px 3px;
    width: 75px;
    font-size: x-small;

    > div {
      text-align: center;
      padding: 1px 0;
    }
  }
}
