@mixin opacity($value) {
  opacity: $value;
  filter: alpha(opacity = ($value * 100));
}

@mixin gradient($from_color, $stop_color) {
}

//
// Bedsider 2021 Palette
//

// Primary colors
$twentyone_accent_green: #14c0a5;
$twentyone_dark_green: #028388;
$twentyone_purple: #8d2e8d;
$twentyone_orange: #de5e04;

// Secondary colors
$twentyone_accent_blue: #83cfdd;
$twentyone_pink: #c9005b;
$twentyone_yellow: #fade1d;
$twentyone_red: #d84200;

// Neutral colors
$twentyone_black: #404040;
$twentyone_dark_gray: #808080;
$twentyone_gray: #aeaeae;
$twentyone_light_gray: #e5e5e5;
$twentyone_extra_light_gray: #f5f5f5;
$twentyone_blue_gray: #eaf7fa;
$twentyone_mint: #d0f2ed;

// Button state colors
$twentyone_hover_field_green: #dcf6f2;
$twentyone_disabled_dark_green: #9fd0d2;
$twentyone_hover_dark_green: #017378;
$twentyone_pressed_dark_green: #016d72;

//
// Pre 2021 Palette
//

$dark_gray: #404040;
$dark_medium_gray: #606060;
$medium_gray: #808080;
$placeholder_gray: #909090;
$light_medium_gray: #a0a0a0;
$light_gray: #bfbfbf;
$x_light_gray: #efefef;
$xx_light_gray: #f6f6f6;

$light_blue: #83cfdd;
$medium_blue: #009cba;
$dark_blue: #006477;
$dark_gray_blue: #004d5d;

$lightest_green: #afca40;
$lighter_green: #acce19; // used in .button:hover
$light_green: $twentyone_dark_green;
$olive: $twentyone_dark_green;
$link_green: $twentyone_dark_green;
$dark_green: $twentyone_dark_green;
$bcb_dark_green: $twentyone_dark_green; // just for BCBenefits temporarily:  https://www.pivotaltracker.com/story/show/165165577

$yellow: #fade1d;
$orange: #faac1d;
$dark_orange: #f37928;

$brown: #7c4a29; // phasing out
$purple: #8d2e8d;
$dark_purple: #521a53;

$pink: #e9007b;
$dark_pink: #c9005b;

// for UMD custom signup page March-April 2012
$umd_red: #c32026;

//
// Named by Function
//

// use to indicate that performing some action will change the state
// of the effected object
$state_change_cue: #f60;

$color_error: $dark_orange;
$black_text: $dark_gray;
$green_button: $link_green;
$editable_content_background: #ffc;
