@import "app/assets/stylesheets/mixins/twentyone_styles";

// @mixin desktop_only {
//   @media (min-width: 850px) {
//     @content;
//   }
// }
//
// @mixin mobile_only {
//   @media (max-width: 849px) {
//     @content;
//   }
// }

@mixin standard_font_size {
  font-size: 0.875rem;
  line-height: 1.25rem;

  @include desktop_and_up {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}

.FullScreenModal {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // width: 100vw; // messed up scrollbar width

  &.redraw {
    transform: translateZ(0);
  }

  .modal-overlay {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include desktop_and_up {
      background-color: rgba(40, 40, 40, 0.6);
      animation: 0.2s ease-out 0s 1 fadeOutBackground;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: 100vh;
    }

    @include tablet_and_below {
      background-color: white;
      height: 100%;
    }
  }

  .full-screen-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    cursor: auto;

    @include tablet_and_below {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }

    @include desktop_and_up {
      width: 780px;
      margin: 48px 0;
      border-radius: 6px;
    }

    .modal-content {
      padding: 0 18px 36px;

      // you might think that these are redundant. Check on safari before removing.
      background-color: white;
      border-radius: 6px;

      @include tablet_and_below {
        padding-bottom: 74px;
      }

      .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;

        h2 {
          font-size: 1.125rem;
          line-height: 1.5;
          font-weight: 600;
          margin: 17px 0;

          @include desktop_and_up {
            font-size: 1.25rem;
            line-height: 1.4;
          }
        }
      }

      .close-x-button {
        height: 36px;
        width: 36px;
        background-position: center center;
        cursor: pointer;

        img {
          width: 17px;
          height: 17px;
        }
      }

      .no-left-padding p {
        padding-left: 0;
      }
    }

    .close-text-container {
      background-color: white;
      bottom: 0;
      box-sizing: border-box;
      box-shadow: 0 -1px 3px 1px rgba(60, 64, 67, 0.15);
      height: 74px;
      padding: 17px 24px;
      position: fixed;
      width: 100%;

      // @include desktop_and_up { display: none; }

      button {
        width: 100%;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}

@keyframes fadeOutBackground {
  0% {
    background-color: rgba(40, 40, 40, 0);
  }
  100% {
    background-color: rgba(40, 40, 40, 0.6);
  }
}

.tooltip-sections {
  display: flex;
  flex-direction: column;

  @include desktop_and_up {
    max-height: none;
  }

  img {
    width: 20px;
    margin-right: 5px;

    @include desktop_and_up {
      width: 22px;
      margin-right: 3px;
    }
  }

  p,
  ul,
  ol {
    @include standard_font_size;
    text-align: left;
    padding-left: 24px;
    margin-bottom: 1.5rem;
    font-weight: 500;

    li {
      margin-left: 24px;
      margin-bottom: 7px;
    }
  }

  ul {
    margin-top: -10px;
  }

  ol {
    margin-top: -10px;
    list-style-type: decimal;
    margin-bottom: 8px;

    li {
      padding-left: 10px;
    }
  }

  .tooltip-section-header {
    @include standard_font_size;
    font-weight: bold;
  }

  .tooltip-section-header-container {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-bottom: 6px;
    align-items: flex-start;
  }

  .with-ab-border {
    border-left-width: 8px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #ccf0eb, #c7c2f7) 1 100%;
    margin: 0 0 1.5rem 24px;
    padding-left: 12px;

    p {
      padding-left: 0;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    strong {
      font-size: 0.6875rem;
      line-height: 0.875rem;
      letter-spacing: 0.1px;

      @include desktop_and_up {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }
}
