@import "app/assets/stylesheets/mixins/twentyone_styles";

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.LocaleSwitcher#LocaleSwitcher {
  // opacity: 0; // done inline to load in gracefully
  animation: fadein 0.2s;
  animation-delay: 0.4s; // give it a short delay to figure out who the user is
  animation-fill-mode: forwards;

  a {
    @include twentyone_buttonfont2;
    padding: 5px 9px 6px;
    border-radius: 6px;
    background-color: $twentyone_dark_green;
    color: white;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
      background-color: #006477;
      text-decoration: none;
    }
  }
}

#LocaleSwitcherModal {
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .full-screen-modal {
    max-width: 660px;
    margin-top: 15%;

    .modal-content {
      position: relative;
      text-align: left;
      padding: 10px 20px 15px;

      button.x_close {
        background-image: url('data:image/svg+xml;utf8, <svg width="100%" height="100%" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><g id="Layer1" transform="matrix(1,0,0,1,-292,-167)"><g transform="matrix(1,0,0,1,292.098,167.047)"><path d="M14.549,1.419L1.503,14.488" style="fill:none;stroke:rgb(128,128,128);stroke-width:1.5px;"/></g><g transform="matrix(-0.00089154,1,-1,-0.00089154,308.085,166.981)"><path d="M14.549,1.419L1.503,14.488" style="fill:none;stroke:rgb(128,128,128);stroke-width:1.5px;"/></g></g></svg>');
        width: 24px;
        height: 24px;

        position: absolute;
        right: 20px;
        top: 12px;

        cursor: pointer;
      }

      .title {
        @include archer_bold;
        color: $light_green;
        font-size: 24px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dotted #bfbfbf;
      }

      .body {
        @include lucida_sans;
        font-style: italic;
        font-size: 14px;
        line-height: 20px;
        margin: 12px 0 26px;
      }

      a {
        text-transform: none; //fighting with navigation styles
        line-height: 36px;
      }

      a.switch {
        @include lucida_sans;
        background: $light_green;
        color: #fff;
        padding: 7px 0;
        display: block;
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        text-align: center;
        margin: 15px 0;
      }

      a.cancel {
        @include lucida_sans;
        color: #000;
        padding: 0;
        font-style: italic;
        display: block;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }

  .close-text-container {
    display: none;
  }
}
