// Z-indices for Bedsider so we can arrange them against each other.

// Backgrounds
$z_desktop_modal_bg: -1;

// Main Content
$z_zero: 0;

$z_main_content_1: 1;
$z_main_content_2: 2;
$z_main_content_3: 3;

$bcbenefits_nav_container: 1;
$infopopup_definition_z: 1;
$infopopup_nib_z: 2;

$z_main_content_5: 5;
$z_main_content_10: 10;
$z_main_content_11: 11;
$z_main_content_12: 12;
$z_main_content_15: 15;

$z_main_content_20: 20;
$z_main_content_30: 30;
$z_main_content_50: 50;
$infopopup_overlay_z: 50;

$quiz_question_z: 90;
$quiz_question_percentage_z: 100;
$question_widget_z: 100;

$z_flyover_100: 100;
$feature_edit_block_z: 100;
$message_widget_arrow_z: 100;
$clinic_offerings_toolip_z: 100;

$method_midension_tooltip_z: 200;

$message_widget_z: 250;

// Navigation & Decorations
$feature_category_filter_backdrop: 299;
$feature_category_filter: 300;

$main_site_nav_z: 350;

$z_desktop_top_nav: 300;
$z_desktop_flash_banners: 301;
$z_desktop_flash_warning: 302;
$z_desktop_flash_error: 303;
$clinic_finder_abortion_directory_override: 500;

// Modals
$z_modal_1000: 1000;
$z_modal_1200: 1000;

$z_modal_1998: 1998;
$z_desktop_modal: 2000;
$cms_panel_z: 1100;

// Clinic Finder
$z_focused_showinfo_pin_icon_number: 1;
$z_pin_hover_box: 30;
$z_clinic_recenter: 300;
$z_clinic_map: 301;
$z_results_swipeable_list: 301;
$z_mobile_sticky_header: 302;
